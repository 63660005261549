import { Link, navigate, withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { Tab, TabBar } from '@rmwc/tabs';

import { getLocationValues } from '../actions/location-state';

import '../styles/header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.checkLocation(),
    };
  }

  onActivate = event => {
    const { index } = event.detail;
    const { tab } = getLocationValues();
    if (index === this.state.activeTab && !tab) return;
    return this.setState(
      { activeTab: index },
      this.handleTabNavigation.bind(this, index)
    );
  };

  handleTabNavigation = index => {
    const { tabs, pathname } = this.props;
    const tabPaths = tabs.map(tab => withPrefix(tab.path));
    let { path } = tabs[index];
    navigate(path, {
      state: { activeTab: index },
      replace: !tabPaths.includes(pathname),
    });
  };

  checkLocation() {
    const { tabs, pathname, search, state } = this.props;
    if (state && state.activeTab) {
      return state.activeTab;
    }
    if (search) {
      const tabMatch = /tab=([\w-]+)/.exec(search);
      if (tabMatch && tabMatch[1] && tabs.length) {
        const searchTabIndex = tabs.findIndex(
          tab => tab.label.toLowerCase() === tabMatch[1].toLowerCase()
        );
        return searchTabIndex;
      }
    }
    if (
      tabs &&
      tabs.length &&
      tabs.map(tab => withPrefix(tab.path)).includes(pathname)
    ) {
      const locationTabIndex = tabs.findIndex(
        tab => withPrefix(tab.path) === pathname
      );
      return locationTabIndex;
    }
    return null;
  }

  render() {
    const { siteTitle, pageTitle, tabs, hidden } = this.props;
    const { activeTab } = this.state;
    return (
      <header className="app-header" hidden={hidden || null}>
        <div className="container">
          <h2>
            <Link to="/">{siteTitle}</Link>
            {pageTitle && `: ${pageTitle}`}
          </h2>
        </div>
        {tabs && (
          <TabBar
            className="container"
            activeTabIndex={activeTab != null ? activeTab : 0}
            onActivate={this.onActivate}
          >
            {tabs.map(tab => (
              <Tab key={tab.path} minWidth>
                {tab.label}
              </Tab>
            ))}
          </TabBar>
        )}
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  pathname: PropTypes.string,
  state: PropTypes.shape({
    activeTab: PropTypes.number,
  }),
  hidden: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
  pageTitle: ``,
};

export default Header;
