import { useStaticQuery, graphql } from 'gatsby';
import { queue } from './snackbar-queue';

export const useAppData = () => {
  const { pvfitWebJson: app, gitBranch } = useStaticQuery(graphql`
    query appQuery {
      pvfitWebJson {
        version
        repository {
          url
        }
      }
      gitBranch(current: { eq: true }) {
        name
        commit
        id
      }
    }
  `);

  app.branch = gitBranch;
  return app;
};

export const checkForUpdate = () => {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.ready
      .then(registration => {
        let hasUpdate = false;

        registration.addEventListener('updatefound', function () {
          // If updatefound is fired, it means that there's
          // a new service worker being installed.
          hasUpdate = true;
        });

        registration.update();

        queue.notify({
          icon: 'sync',
          title: 'Checking for updates...',
          timeout: 3000,
        });

        setTimeout(() => {
          if (!hasUpdate) {
            queue.notify({
              icon: 'check',
              title: 'App is up to date.',
            });
          }
        }, 2800);
      })
      .catch(error => {
        console.log('Service worker not ready:', error);
      });
  }
};
