import React from 'react';

const getKey = key => key.replace(/\W+/g, '-');

export const getLocationValues = () =>
  window.location.search
    ? window.location.search
        .slice(1)
        .split('&')
        .reduce((map, pair) => {
          const [key, value] = pair.split('=');
          map[getKey(key)] = value;
          return map;
        }, {})
    : {};

export const useLocationState = (defaultValue, key) => {
  const [value, setValue] = React.useState(() => {
    const locationValue = key ? getLocationValues()[getKey(key)] : null;
    return locationValue != null ? locationValue : defaultValue;
  });

  React.useEffect(() => {
    if (key && value) {
      window.history.replaceState(
        { [getKey(key)]: value },
        '',
        `?${[getKey(key), value].join('=')}`
      );
    }
  }, [key, value]);

  return [value, setValue];
};
