import React from 'react';
import { PropTypes } from 'prop-types';

import { Icon } from '@rmwc/icon';
import { Tooltip } from '@rmwc/tooltip';

import { useAppData, checkForUpdate } from '../actions/app-data';

import '../styles/footer.scss';

const Footer = ({ hidden, children }) => {
  const { repository, version, branch } = useAppData();
  return (
    <footer hidden={hidden}>
      <div className="container">
        <button
          className="version-check"
          title="Check for Updates"
          onClick={event => {
            event.preventDefault();
            checkForUpdate();
          }}
        >
          {version}
        </button>{' '}
        © {new Date().getFullYear()},{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://intelligentmeasurementsystems.com"
        >
          Intelligent Measurement Systems LLC
        </a>
        <div className="repo-link">
          <Tooltip
            align="topRight"
            content={[branch.name, branch.commit].join(' @ ')}
          >
            <a href={[repository.url, 'tree', branch.commit].join('/')}>
              <Icon icon="code" />
            </a>
          </Tooltip>
        </div>
      </div>
      {children}
    </footer>
  );
};

Footer.propTypes = {
  version: PropTypes.string,
  hidden: PropTypes.bool,
};

export default Footer;
