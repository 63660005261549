/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

import Header from './header';
import Nav from './nav';
import Footer from './footer';
import { SnackbarQueue } from '@rmwc/snackbar';

import WithScrollEffect from '../actions/scroll-effect';
import { useAppData } from '../actions/app-data';
import { queue } from '../actions/snackbar-queue';

// katex math rendering
import 'katex/dist/katex.min.css';

import '../styles/layout.scss';

const Layout = ({ lang, title, description, meta, slug, tabs, children }) => {
  const main = React.useRef();

  const { settingsYaml: settings } = useStaticQuery(graphql`
    query SettingsQuery {
      settingsYaml {
        siteTitle
        siteDescription
        socialMediaCard {
          image
        }
        twitterAuthor
        googleTrackingId
        menu {
          label
          url
        }
      }
    }
  `);

  const { version } = useAppData();

  let metaTitle = title;
  const metaDescription = description || settings.siteDescription;

  const metaData = !Array.isArray(meta)
    ? Object.entries(meta).map(([key, value]) => {
        if (key === 'title') {
          metaTitle = value;
        }
        return {
          name: key,
          content: value,
        };
      })
    : meta;

  settings.menu.forEach(item => {
    if (slug === false && item.url === '/') {
      item.active = true;
      return;
    }
    item.active = item.url === `/${slug}`;
  });

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${settings.siteTitle}`}
        meta={[
          {
            name: `application-name`,
            content: [settings.siteTitle, version].join(' '),
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          settings.twitterAuthor && {
            name: `twitter:creator`,
            content: settings.twitterAuthor,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .filter(_ => !!_)
          .concat(metaData)}
      />
      <WithScrollEffect
        target={main}
        render={({ active, position, direction, max }) => (
          <>
            <Location>
              {({ location }) => (
                <Header
                  hidden={active && position > 80 && direction === 'down'}
                  siteTitle={settings.siteTitle}
                  pageTitle={metaTitle}
                  tabs={tabs}
                  {...location}
                />
              )}
            </Location>
            <Nav menu={settings.menu} />
            <main ref={main}>
              <div className="container">{children}</div>
            </main>
            <Footer hidden={active && !max}>
              <SnackbarQueue messages={queue.messages} />
            </Footer>
          </>
        )}
      />
    </>
  );
};

Layout.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.object,
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  lang: `en`,
  title: ``,
  description: ``,
  meta: {},
};

export default Layout;
