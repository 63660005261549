import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'gatsby';
import { Icon } from '@rmwc/icon';

import '../styles/nav.scss';

const Nav = ({ menu }) => {
  const [menuActive, setActive] = useState(false);

  return (
    <nav className={`app-navigation ${menuActive ? 'active' : ''}`}>
      <div className="menu-controls">
        {menuActive ? (
          <Icon icon="close" onClick={() => setActive(false)} />
        ) : (
          <Icon icon="menu" onClick={() => setActive(true)} />
        )}
      </div>
      <div className="container">
        {menu.map((item, i) => (
          <Link
            className={`nav-link ${item.active ? 'active' : ''}`}
            key={i}
            to={item.url}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </nav>
  );
};

Nav.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default Nav;
