import React from 'react';
import PropTypes from 'prop-types';

const WithScrollEffect = ({ target, render }) => {
  const [scrollState, setScrollState] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [scrollDirection, setScrollDirection] = React.useState('down');
  const [scrollMax, setScrollMax] = React.useState(false);

  const threshold = 100;
  const debounced = new Map();

  React.useEffect(() => {
    const ref = target.current;
    const debounce = fn => {
      clearTimeout(debounced.get(fn.name));
      debounced.set(fn.name, setTimeout(fn, 300));
    };
    const onScroll = _event => {
      const shouldUpdate = Math.abs(scrollPosition - ref.scrollTop) > threshold;
      if (!shouldUpdate) {
        return;
      }
      const direction = scrollPosition - ref.scrollTop >= 0 ? 'up' : 'down';
      const isMax =
        ref.scrollHeight - ref.scrollTop - ref.offsetHeight < threshold;
      setScrollDirection(direction);
      setScrollPosition(ref.scrollTop);
      setScrollMax(isMax);
    };
    const onResize = _event => {
      setScrollState(window.matchMedia('(max-height: 40rem)').matches); // 40rem to match media query
    };

    if (scrollState) {
      ref.addEventListener('scroll', debounce.bind(null, onScroll));
    }
    window.addEventListener('resize', debounce.bind(null, onResize));
    window.addEventListener('orientationchange', debounce.bind(null, onResize));

    // initialize
    onResize();

    return () => {
      debounced.size && debounced.forEach(handler => clearTimeout(handler));
      ref.removeEventListener('scroll', debounce);
      window.removeEventListener('resize', debounce);
      window.removeEventListener('orientationchange', debounce);
    };
  });

  return render({
    active: scrollState,
    position: scrollPosition,
    direction: scrollDirection,
    max: scrollMax,
  });
};

WithScrollEffect.propTypes = {
  target: PropTypes.shape({
    current: PropTypes.any,
  }),
  render: PropTypes.func,
};

export default WithScrollEffect;
